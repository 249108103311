import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="darmsanierung" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Darmsanierung`}</h1>
      <h3 {...{
        "id": "darmsanierung"
      }}>{`Darmsanierung`}</h3>
      <p>{`Die Funktionen des gesamten Magen-Darm-Kanals sind von der Anwesenheit einer Vielzahl entsprechender bakterieller Keime (Symbionten) abhängig, ohne die wir gar nicht überleben könnten!`}</p>
      <p>{`Bei einer gestörten Darmflora (Dysbakterie) können sich zahlreiche Beschwerden ergeben:`}</p>
      <ul>
        <li parentName="ul">{`Blähungen, Verstopfung, Durchfall, Mangelerscheinungen,`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/beschwerdebilder/allergien_unvertraeglichkeiten"
          }}>{`Allergien / Unverträglichkeiten`}</a>{`,`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/beschwerdebilder/immunstaerkung"
          }}>{`Immunschwäche`}</a>{` mit ständigen Infekten,`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/beschwerdebilder/chronische-hauterkrankung"
          }}>{`chronische Hauterkrankungen`}</a>{` mit Ekzemen und Juckreiz bis hin zu`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/beschwerdebilder/chronische-darmerkrankung"
          }}>{`chronische Darmerkrankung`}</a>{` wie Colitis ulcerosa und Morbus Crohn.`}</li>
      </ul>
      <p>{`Auf der Basis und nach Auswertung einer vorangegangenen entsprechend aussagefähigen `}<a parentName="p" {...{
          "href": "/diagnoseverfahren/stuhl-labor"
        }}>{`Stuhl-Untersuchung`}</a>{` kann therapeutisch die gezielte Darmsanierung erfolgen:`}</p>
      <ul>
        <li parentName="ul">{`Beseitigung darmfremder Keime (Bakterien, Pilze usw.)`}</li>
        <li parentName="ul">{`Regeneration der entzündlich veränderten Schleimhaut`}</li>
        <li parentName="ul">{`Regeneration der normalen/physiologischen Darmflora durch`}</li>
        <li parentName="ul">{`Optimierung der Verdauungsleistung von Magen, Leber und Bauchspeicheldrüse`}</li>
        <li parentName="ul">{`Stabilisierung des Darm-Immunsystems (= „immunologisches Zentrum“ unseres Körpers!)`}</li>
        <li parentName="ul">{`Ernährungsumstellung`}</li>
      </ul>
      <p>{`Ob und wie intensiv die aufgeführten Punkte therapiebedürftig sind, ist individuell sehr unterschiedlich und kann anhand der Stuhl-Untersuchung definiert werden.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      